<template>
  <main>
    <div v-if="offering && investment">
      <h6>Investment Review</h6>
      <div class="form-outline mb-4 container-fluid">
        <div class="row">
          <div class="name">Offering Name</div>
          <div class="serious">{{ offering.name }}</div>
        </div>
        <div class="row">
          <div class="col-md">
            <div class="name">
              Investment ID
            </div>
            <div class="serious">{{ investment.id }}</div>
          </div>
          <div class="col-md">
            <div class="name">Investment Type</div>
            <div v-if="investment.investmentType == 'joint'" class="serious">
              Joint Investment
            </div>
            <div v-if="investment.investmentType == 'individual'" class="serious">
              Individual Investment
            </div>
            <div v-if="investment.investmentType == 'entity'" class="serious">
              Entity Investment
            </div>
          </div>
          <div class="col-md">
            <div v-if="investment.primaryAccreditedInvestor == 'yes'">
              <div class="name">Investor Type</div>
              <div v-if="investment.investmentType == 'joint'" class="serious">
                Accredited Investors
              </div>
              <div v-if="investment.investmentType == 'individual'" class="serious">
                Accredited Investor
              </div>
              <div v-if="investment.investmentType == 'entity'" class="serious">
                Accredited Entity
              </div>
            </div>
          </div>
          <div v-if="investment.status == 'cancelled'" class="col-md">
            <div class="name">Cancelled</div>
            <div class="serious">{{ investment.cancelReason }}</div>
          </div>
          <div v-if="investment.status == 'C2MError'" class="col-md">
            <div class="name">Status</div>
            <div class="serious">
              Error under review by administrators
            </div>
          </div>
          <div v-else class="col-md">
            <div class="name">Status</div>
            <div class="serious">{{ investment.status }}</div>
          </div>
        </div>
      </div>
      <div class="form-outline mb-4 container-fluid"></div>
      <div v-if="investment.investmentType == 'entity'">
        <h6>Entity</h6>

        <div class="form-outline mb-2 container-fluid">
          <div class="row">
            <div class="col-md-3">
              <div class="name">Entity Name</div>
              <div class="serious">
                {{ investment.entityName }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="name">Entity Start Date</div>
              <div class="serious">
                {{ toPrettyDate(investment.entityStartDate) }}
              </div>
            </div>

            <div class="col-md-3">
              <div class="name">Entity Type</div>
              <div class="serious">{{ investment.entityType }}</div>
            </div>
            <div class="col-md-3">
              <div class="name">Entity Industry</div>
              <div class="serious">{{ investment.entityIndustry }}</div>
            </div>
          </div>
        </div>
        <div class="form-outline mb-2 container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div v-if="investment.isFinCenRegulated" class="serious">
                This entity is FinCen Regulated
              </div>
              <div v-else class="serious">
                This entity not FinCen Regulated
              </div>
            </div>
            <div class="col-md-6">
              <div v-if="investment.isUSEntity">
                <div class="serious">This is a US Entity</div>
              </div>
              <div v-else>
                <div v-if="investment.isEntityFtin">
                  <span class="name">FTIN: </span>
                  <span class="serious">{{ investment.entityTaxId }}</span>
                </div>
                <div v-else class="serious">
                  This entity does not have an FTIN
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col-md-7">
              <div class="name">Entity Address</div>
              <div class="serious">
                <div>
                  {{ investment.entityStreetAddress1 }}
                </div>
                <div v-if="investment.entityStreetAddress2">
                  {{ investment.entityStreetAddress2 }}
                </div>
                <div>
                  {{ investment.entityCity }},
                  {{ investment.entityState }}
                  {{ investment.entityPostalCode }}
                  {{ investment.entityCountry }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6>Purchaser Representative / Owner Signatory</h6>
        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="serious">
                {{ investment.entityRepresentativeName }},
                {{ investment.entityRepresentativeTitle }}
              </div>
              <div class="serious">
                {{ investment.entityRepresentativePhoneNumber }}
              </div>
              <div class="serious">
                {{ investment.entityRepresentativeEmail }}
              </div>
            </div>
            <div class="col-md-6">
              <div>{{ investment.entityRepresentativeStreetAddress1 }}</div>
              <div v-if="investment.entityRepresentativeStreetAddress2">
                {{ investment.entityRepresentativeStreetAddress2 }}
              </div>
              <div>
                {{ investment.entityRepresentativeCity }}
                {{ investment.entityRepresentativeState }}
                {{ investment.entityRepresentativePostalCode }}
                {{ investment.entityRepresentativeCountry }}
              </div>
            </div>
          </div>
        </div>
        <h6>Owners (> 10% ownership)</h6>
        <div class="form-outline mb-4 container-fluid">
          <div v-if="investment.isEntityOwnersAccredited" class="serious mb-2">
            All Owners are Accredited
          </div>
          <div v-else>Owners are NOT Accredited</div>
          <div class="row mb-2" v-for="(owner, index) in investment.owners" :key="index">
            <div class="serious fw-bold mb-2">Owner #{{ index + 1 }}</div>
            <div class="row">
              <div class="col-md-6">
                <div class="serious">{{ owner.entityOwnerName }}</div>
                <div class="serious">{{ owner.entityOwnerDateOfBirth }}</div>
                <div class="serious">{{ owner.entityOwnerEmail }}</div>
              </div>
              <div class="col-md-6">
                <div>{{ owner.entityOwnerStreetAddress1 }}</div>
                <div v-if="owner.entityOwnerStreetAddress2">
                  {{ owner.entityOwnerStreetAddress2 }}
                </div>
                <div>
                  {{ owner.entityOwnerCity }}
                  {{ owner.entityOwnerState }}
                  {{ owner.entityOwnerPostalCode }}
                  {{ owner.entityOwnerCountry }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h6 v-if="investment.investmentType == 'joint'">Primary Investor</h6>
        <h6 v-else>Investor</h6>
        <div class="form-outline mb-2 container-fluid">
          <div class="row">
            <div class="col-md-4">
              <div class="name">Name</div>
              <div class="serious">
                {{ investment.primaryFullName }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="name">Date of Birth</div>
              <div class="serious">
                {{ toPrettyDate(investment.primaryDateOfBirth) }}
              </div>
            </div>

            <div class="col-md-4">
              <div class="name">Email</div>
              <div class="serious">{{ investment.primaryEmail }}</div>
            </div>
          </div>
        </div>
        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col-md-7">
              <div class="name">Address</div>
              <div class="serious">
                <div>
                  {{ investment.primaryAddressLine1 }}
                </div>
                <div v-if="investment.primaryAddressLine2">
                  {{ investment.primaryAddressLine2 }}
                </div>
                <div>
                  {{ investment.primaryCity }},
                  {{ investment.primaryState }}
                  {{ investment.primaryPostalCode }}
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="name">Phone Number</div>
              <div class="serious">
                {{ formatPhoneNumber(investment.primaryPhoneNumber) }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="investment.investmentType == 'joint'">
          <h6>Secondary Investor</h6>
          <div class="form-outline mb-2 container-fluid">
            <div class="row">
              <div class="col-md-4">
                <div class="name">Name</div>
                <div class="serious">
                  {{ investment.secondaryFullName }}
                </div>
              </div>
              <div class="col-md-3">
                <div class="name">Date of Birth</div>
                <div class="serious">
                  {{ toPrettyDate(investment.secondaryDateOfBirth) }}
                </div>
              </div>

              <div class="col-md-4">
                <div class="name">Email</div>
                <div class="serious">{{ investment.secondaryEmail }}</div>
              </div>
            </div>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md-7">
                <div class="name">Address</div>
                <div class="serious">
                  <div>
                    {{ investment.secondaryAddressLine1 }}
                  </div>
                  <div v-if="investment.secondaryAddressLine2">
                    {{ investment.secondaryAddressLine2 }}
                  </div>
                  <div>
                    {{ investment.secondaryCity }},
                    {{ investment.secondaryState }}
                    {{ investment.secondaryPostalCode }}
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="name">Phone Number</div>
                <div class="serious">
                  {{ formatPhoneNumber(investment.secondaryPhoneNumber) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6>Investment Details</h6>
      <div class="form-outline mb-4 container-fluid">
        <div v-if="investment.primaryAccreditedInvestor == 'no'" class="row">
          <div class="col-md-4">
            <div v-if="investment.investmentType == 'individual'" class="name">
              Annual Income
            </div>
            <div v-else class="name">Joint Annual Income</div>
            <div class="serious">
              ${{ numberWithCommas(investment.primaryAnnualIncome) }}
            </div>
          </div>
          <div class="col-md-4">
            <div class="name">Household Net Worth</div>
            <div class="serious">
              ${{ numberWithCommas(investment.primaryNetWorth) }}
            </div>
          </div>
          <div v-if="investment.primaryOtherInvestments == 'yes'" class="col-md-4">
            ${{ investment.primaryOtherInvestmentTotal }}
          </div>
        </div>
      </div>
      <div class="form-outline mb-4 container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="name">Total Investment</div>
            <div class="serious">
              ${{ numberWithCommas(investment.totalInvestment) }}
            </div>
          </div>
          <div class="col-md-4">
            <div class="name">Total Shares</div>
            <div class="serious">
              {{ numberWithCommas(investment.totalShares) }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-outline mb-4 container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="name">Payment Method</div>
            <div class="serious">{{ investment.paymentMethod }}</div>
          </div>
          <div v-if="investment.paymentMethod == 'ACH'" class="col-md-4">
            <div class="name">Account Type</div>
            <div class="serious">{{ investment.paymentAccountType }}</div>
          </div>
        </div>
      </div>
      <h6>Subscription agreement for the {{ offering.name }} offering</h6>
      <div class="row single" @click="handleDocumentLoad">
        <div class="col-md-2">
          <span class="btn-link">
            <div class="subnav-thumbnail">
              <img src="@/assets/img/pdf.png" />
            </div>
          </span>
        </div>
        <div class="col-md">
          The agreement was signed {{ investment.zsSignatureTime }}
        </div>
        <div class="col-md">
          <div v-if="errorDocument" class="error">
            {{ errorDocument }}
          </div>
          <div v-else>
            <span v-if="documentShowing">Hide</span>
            <span v-else>Show</span>
          </div>
        </div>
      </div>
      <div v-if="downloadURL && documentShowing">
        <embed class="d-none d-md-block" :src="downloadURL" type="application/pdf" width="100%" height="600px" />
        <a class="d-md-none" :href="downloadURL" download="SubscriptionDoc">Download</a>
      </div>

      <div v-if="errorOffering" class="error">{{ errorOffering }}</div>
      <div v-if="errorInvestment" class="error">{{ errorInvestment }}</div>
      <div v-if="errorCancel" class="error">{{ errorCancel }}</div>
      <div class="row">
        <div class="col-md d-flex justify-content-center p-5">
          <button class="btn btn-secondary" @click="router.go(-1)">
            <i class="bi bi-arrow-left"></i>Back to Investments
          </button>
        </div>
      </div>
      <div v-if="errorOffering">{{ errorOffering }}</div>
    </div>
    <ModalDelete title="Delete Investment?" @confirm-delete="handleCancelInvestment">
      Are you sure you want to cancel and permanently delete this investment?
      This action cannot be undone.
    </ModalDelete>
  </main>
  <Footer v-if="offering" />
</template>

<script>
import ModalDelete from "@/components/modals/ModalDelete.vue";
import Footer from "@/components/navigation/Footer.vue";
import getDocument from "@/composables/getDocument";
import usePrettyNumbers from "@/composables/usePrettyNumbers";
import { useRouter } from "vue-router";
import { format } from "date-fns";
import { projectFunctions } from "@/firebase/config";
import { ref } from "@vue/reactivity";
import useDocument from "@/composables/useDocument";
import { timestamp } from "@/firebase/config";
import useStorage from "@/composables/useStorage";

export default {
  props: ["offeringId", "investmentId"],
  components: { Footer, ModalDelete },
  setup(props) {
    const documentShowing = ref(false);
    const downloadURL = ref("");
    const cancelPending = ref([]);
    const today = new Date();
    const isInvesting = ref(false);
    const isPending = ref(false);
    const errorCancel = ref(null);

    const router = useRouter();

    const { error: errorDocument, getSubscriptionDocumentURL } = useStorage();

    const {
      formatPhoneNumber,
      numberWithCommas,
      toPrettyDate
    } = usePrettyNumbers();

    const cancelInvestment = projectFunctions.httpsCallable("cancelInvestment");

    const { error: errorOffering, document: offering } = getDocument(
      "offerings",
      props.offeringId
    );
    const { error: errorInvestment, document: investment } = getDocument(
      "investments",
      props.investmentId
    );

    const resubmitInvestment = projectFunctions.httpsCallable(
      "resubmitInvestment"
    );

    const { error: errorUpdate, updateDoc: updateInvestment } = useDocument(
      "investments",
      props.investmentId
    );

    const isCancellable = closingDate => {
      console.log(`Closing Date: ${closingDate}`);
      if (closingDate) {
        const closingParse = closingDate.split("-");
        const closeDate = new Date(
          closingParse[0],
          closingParse[1] - 1,
          closingParse[2]
        );
        closeDate.setHours(23, 59, 59, 0);
        const timeMilSec = closeDate.getTime() - today.getTime();
        console.log(`Time: ${timeMilSec}`);
        return timeMilSec / (1000 * 60 * 60 * 24) >= 2;
      } else {
        return false;
      }
    };

    const handleDocumentLoad = async () => {
      if (!downloadURL.value) {
        downloadURL.value = await getSubscriptionDocumentURL(
          investment.value.investorId,
          investment.value.id
        );
      }
      documentShowing.value = !documentShowing.value;
    };

    const handleCancelInvestment = async () => {
      try {
        cancelPending.value.push(investment.value.id);
        errorCancel.value = null;
        console.log(`Cancel This FA investment ID: ${investment.value.id}`);
        const cancelInvestmentResult = await cancelInvestment({
          offeringId: investment.value.offeringId,
          totalInvestment: investment.value.totalInvestment,
          investmentId: investment.value.id,
          owners: investment.value.owners,
          cancelReason: investment.value.cancelReason
        });
        console.log(`Result: ${cancelInvestmentResult.data.status}`);
        if (cancelInvestmentResult.data.error) {
          errorCancel.value = cancelInvestmentResult.data.error;
          cancelPending.value.pop(investment.value.id);
        } else {
          router.go(-1);
        }
      } catch (err) {
        errorCancel.value = `Error: ${err.message}`;
        cancelPending.value.pop(investment.value.id);
      }
    };

    const handleConfirmInvestment = async () => {
      isInvesting.value = true;
      console.log("Resubmitting an investment...");
      isPending.value = true;
      try {
        await updateInvestment({
          paymentAccountType: investment.value.paymentAccountType,
          editedAt: timestamp(),
          editedBy: "Admin"
        });
        if (errorUpdate.value) {
          console.log(`Error: ${errorUpdate.value}`);
        } else {
          console.log(`Successful Update`);
          const newInvestment = await resubmitInvestment({
            investmentId: investment.value.id
          });
          console.log(`Investment Successful ${newInvestment.data.status}`);

          router.go(-1);
        }
        isInvesting.value = false;
      } catch (err) {
        console.log(`Server Error: ${err.message}`);
        isInvesting.value = false;
      }
    };

    return {
      errorDocument,
      errorOffering,
      errorInvestment,
      errorCancel,
      formatPhoneNumber,
      numberWithCommas,
      toPrettyDate,
      offering,
      investment,
      cancelPending,
      format,
      router,
      handleConfirmInvestment,
      handleCancelInvestment,
      isCancellable,
      handleDocumentLoad,
      downloadURL,
      documentShowing
    };
  }
};
</script>

<style>
.btn-link {
  text-decoration: none;
}
</style>